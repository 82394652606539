
import { defineComponent, onMounted, ref } from 'vue';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import useNotificationForUser from '@/store/useNotificationForUser';
import toDateString from '@/utillities/toDateString';
import UserNotificationViewModel from '@/Model/UserNotificationViewModel';
import { onBeforeRouteLeave } from 'vue-router';
import { customerState } from '../store';
export default defineComponent({
  Name: 'UserLogs',
  setup() {
    const { getLogs, markLogsAsRead, pageModel } = useNotificationForUser();
    const logs = ref<Array<UserNotificationViewModel>>();
    const delay = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    const loading = ref<boolean>(false);
    const isRead = (readDate: string) => {
      if (readDate) {
        const date = parseISO(readDate.toString());
        const value = isAfter(date, new Date(2000, 1, 1));
        return value;
      }
      return false;
    };
    const goTo = async (page: number) => {
      loading.value = true;
      getLogs(page, pageModel.size).then(
        (response: Array<UserNotificationViewModel>) => {
          logs.value = response;
          loading.value = false;
        },
        () => {
          loading.value = false;
        }
      );
    };
    const goBack = async () => {
      const page = pageModel.value.page - 1;
      await goTo(page);
    };
    const goForward = async () => {
      const page = pageModel.value.page + 1;
      await goTo(page);
    };
    onBeforeRouteLeave(() => {
      customerState.setInspection(null);
    });
    onMounted(async () => {
      loading.value = true;
      getLogs().then(
        (response: Array<UserNotificationViewModel>) => {
          logs.value = response;
          loading.value = false;
        },
        () => {
          loading.value = false;
        }
      );
      await delay(10000);
      await markLogsAsRead();
    });
    return {
      logs,
      toDateString,
      isRead,
      pageModel,
      goTo,
      goForward,
      goBack,
      loading
    };
  }
});
