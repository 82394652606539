<template>
  <div class="spinner-border text-primary" role="status" v-if="loading">
    <span class="visually-hidden">Loading...</span>
  </div>
  <template v-else>
    <span v-if="!logs || (logs && logs.length === 0)">Ingen beskeder</span>
    <table v-else class="table table-hover table-sm">
      <thead>
        <tr>
          <td></td>
          <th class="text-start">Dato</th>
          <th class="text-start">Hændelse</th>
          <th class="text-start">Navn</th>
          <th class="text-start">Lokation</th>
          <th class="text-start">Type</th>
          <th class="text-start">Bruger</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="log in logs" :key="log.id">
          <router-link
            v-slot="{ navigate }"
            :to="{
              name: 'inspection_documents',
              params: {
                id: log.inspection.customerId,
                inspection: log.inspection.id
              }
            }"
            custom
          >
            <tr @click="navigate">
              <td>
                <span v-if="!isRead(log.readAt)" class="badge bg-primary">
                  Ny
                </span>
              </td>
              <td class="text-start" nowrap>{{ toDateString(log.created) }}</td>
              <td class="text-start">{{ log.message }}</td>
              <td class="text-start">{{ log.inspection.name }}</td>
              <td class="text-start">{{ log.inspection.location }}</td>
              <td class="text-start">{{ log.inspection.type }}</td>
              <td class="text-start" nowrap>{{ log.createdBy }}</td>
            </tr>
          </router-link>
        </template>
      </tbody>
    </table>
    <nav aria-label="Page navigation example" v-if="pageModel">
      <ul class="pagination">
        <li class="page-item" v-if="pageModel.page > 1">
          <a
            class="page-link"
            href="#"
            aria-label="Previous"
            @click="goBack(n)"
            v-if="pageModel.page > 1"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: pageModel.page === n }"
          v-for="n in pageModel.pages()"
          :key="n"
        >
          <a class="page-link" @click="goTo(n)">
            {{ n }}
          </a>
        </li>
        <li class="page-item">
          <a class="page-link" @click="goForward(n)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </template>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';
import useNotificationForUser from '@/store/useNotificationForUser';
import toDateString from '@/utillities/toDateString';
import UserNotificationViewModel from '@/Model/UserNotificationViewModel';
import { onBeforeRouteLeave } from 'vue-router';
import { customerState } from '../store';
export default defineComponent({
  Name: 'UserLogs',
  setup() {
    const { getLogs, markLogsAsRead, pageModel } = useNotificationForUser();
    const logs = ref<Array<UserNotificationViewModel>>();
    const delay = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    const loading = ref<boolean>(false);
    const isRead = (readDate: string) => {
      if (readDate) {
        const date = parseISO(readDate.toString());
        const value = isAfter(date, new Date(2000, 1, 1));
        return value;
      }
      return false;
    };
    const goTo = async (page: number) => {
      loading.value = true;
      getLogs(page, pageModel.size).then(
        (response: Array<UserNotificationViewModel>) => {
          logs.value = response;
          loading.value = false;
        },
        () => {
          loading.value = false;
        }
      );
    };
    const goBack = async () => {
      const page = pageModel.value.page - 1;
      await goTo(page);
    };
    const goForward = async () => {
      const page = pageModel.value.page + 1;
      await goTo(page);
    };
    onBeforeRouteLeave(() => {
      customerState.setInspection(null);
    });
    onMounted(async () => {
      loading.value = true;
      getLogs().then(
        (response: Array<UserNotificationViewModel>) => {
          logs.value = response;
          loading.value = false;
        },
        () => {
          loading.value = false;
        }
      );
      await delay(10000);
      await markLogsAsRead();
    });
    return {
      logs,
      toDateString,
      isRead,
      pageModel,
      goTo,
      goForward,
      goBack,
      loading
    };
  }
});
</script>
